import { convertToCamelCase } from "../utils/types";
import {
  ApiError,
  DefaultService,
  MoreInformationRequestedCase,
} from "../api.generated";
import type {
  InformationRequestedUpdateCase,
  InformationRequestedCase,
} from "../models/provideInformation";

type MoreInformation = {
  customerId: string;
  caseReference: string;
};

type SubmitMoreInformation = MoreInformation & {
  requestBody: InformationRequestedUpdateCase;
};

export async function getMoreInformation({
  customerId,
  caseReference,
}: MoreInformation): Promise<InformationRequestedCase> {
  try {
    let response = await DefaultService.getApiV1MoreInformationRequested(
      customerId,
      caseReference
    );

    return convertToCamelCase<
      MoreInformationRequestedCase,
      InformationRequestedCase
    >(response);
  } catch (e) {
    let result = <ApiError>e;
    console.error(
      `Error when calling submit more information api: ${result.message}`,
      e
    );
    throw e;
  }
}

export async function submitMoreInformation({
  customerId,
  caseReference,
  requestBody,
}: SubmitMoreInformation): Promise<{ message: string }> {
  const requestBodySnakeCase = {
    information_provided: requestBody.informationProvided,
    attachments: requestBody.attachments,
  };
  try {
    let response = await DefaultService.postApiV1MoreInformationRequested(
      customerId,
      caseReference,
      requestBodySnakeCase
    );

    return response;
  } catch (e) {
    let result = <ApiError>e;
    console.error(
      `Error when calling submit more information api: ${result.message}`,
      e
    );
    throw e;
  }
}
