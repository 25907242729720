<script setup lang="ts">
import { ref } from "vue";
import {
  TwoText,
  TwoTextField,
  TwoFileUpload,
  TwoButton,
  TwoBox,
  TwoDialog,
  FileUploadItem,
} from "@wegift/two-components";

import { assert } from "../utils/typescript";
import {
  BrandApprovalFileUploadResponse,
  BrandApprovalApplicationService,
} from "../api/brand-approval-application-service";

const emit = defineEmits(["handleSubmitMoreInformation"]);

const { files, preSignedUrlsResponses } = defineProps<{
  files: string[];
  preSignedUrlsResponses: Record<string, BrandApprovalFileUploadResponse>;
}>();

const attachments = ref<string[]>(files);

let moreInformation = ref<string>("");
let showDialog = ref(false);

function handleMoreInformation(value: string) {
  moreInformation.value = value;
}

function handleSend() {
  handleDialog();
}

function handleDialog() {
  showDialog.value = !showDialog.value;
}

function handleSubmit() {
  handleDialog();
  emit("handleSubmitMoreInformation", {
    informationProvided: moreInformation.value,
    attachments: Object.values(attachments.value),
  });
}

const getUploadedFilesKeys = (
  files: FileUploadItem[],
  existingFileFieldKeys: string[] = []
): string[] => {
  return (
    files
      .filter((file) => file.uploaded)
      // if preselected file, return find existing file key and return it, else return signed url response field key set by backend
      .map((file) => {
        if (file.preSelected) {
          return existingFileFieldKeys.find(
            (key) => file.name === extractActualFileNameFromFileKey(key)
          );
        } else return preSignedUrlsResponses[file.name].fields.key;
      })
  );
};

const uploadFileFn = async (file: FileUploadItem) => {
  const preSignedUrlUploadResponse =
    await BrandApprovalApplicationService.uploadFilesWithBrandApprovalApplication(
      { fileName: file.name }
    );
  const selectedFileRaw = file.raw;
  assert(selectedFileRaw);
  const formData = new FormData();
  // store response for constructing get urls on successful upload
  preSignedUrlsResponses[file.name] = preSignedUrlUploadResponse;
  // copy form fields returned by signed url to FormData
  Object.entries(preSignedUrlUploadResponse.fields).forEach((entry) => {
    if (typeof entry[1] === "string") formData.append(entry[0], entry[1]);
  });
  formData.append("file", selectedFileRaw!);
  return fetch(preSignedUrlUploadResponse.url, {
    method: "POST",
    body: formData,
  });
};

const extractActualFileNameFromFileKey = (fileKey: string) => {
  // backend prefixes {uuid}_ to file name for creating file key so we have to split, remove first elem and rejoin to get back file name
  return fileKey.split("_").slice(1).join("");
};
</script>

<template>
  <two-box>
    <two-text class="mb-6 font-heading font-semiblack" type="text-xl" tag="h2">
      Reply with more information
    </two-text>
    <two-text type="text-base" tag="p">
      Please answer any questions and add your notes below.
    </two-text>
    <two-text-field
      multiline
      class="mb-6 w-6/12"
      placeholder="Your reply..."
      v-model="moreInformation"
      @change="handleMoreInformation"
    />
    <p>
      <two-text type="text-xl font-semiblack font-heading">
        Upload files
      </two-text>
      <two-text type="text-xl font-heading font-light">(optional)</two-text>
    </p>
    <two-file-upload
      class="mt-4 mb-6"
      :upload-file-fn="uploadFileFn"
      :multiple="true"
      @change="attachments = getUploadedFilesKeys($event, attachments)"
      >Browse files</two-file-upload
    >
    <two-button
      class="w-[268px]"
      :class="moreInformation.length < 1 ? 'btn-dark' : 'btn-primary'"
      :disabled="moreInformation.length < 1"
      @click="handleSend"
    >
      Send
    </two-button>
    <TwoDialog
      :show="showDialog"
      @handle-close="handleDialog"
      @handle-no="handleDialog"
      @handle-yes="handleSubmit"
      title="Are you sure you wish to send this message?"
      description="Please make sure you have answered all questions in full and attached your files. <br/>
You will not be able to change your message or add more attachments once this has been sent."
      btn-cancel-text="No, go back"
      btn-confirm-text="Yes, send"
    />
  </two-box>
</template>
