<script setup lang="ts">
import {
  TwoLineItem,
  TwoText,
  TwoBox,
  TwoDivider,
} from "@wegift/two-components";

import { AttachmentUrl } from "../api.generated/models/AttachmentUrl";

const { replyInformation } = defineProps<{
  title: string;
  replyInformation: {
    informationProvided: string | undefined | null;
    informationProvidedDatetime: string | undefined;
    attachmentUrls?: AttachmentUrl[] | null;
  };
}>();
</script>
<template>
  <two-text class="mb-4 font-heading font-semiblack" type="text-xl" tag="h1">
    {{ title }}
  </two-text>
  <two-box class="mb-6">
    <two-line-item
      title="Date:"
      :description="replyInformation.informationProvidedDatetime || ''"
    />
    <two-divider class="mt-2 mb-3" />
    <two-line-item
      title="Message sent:"
      :description="`“${replyInformation.informationProvided || ''}”`"
      :quote="true"
    />
    <two-divider class="mt-2 mb-3" />
    <div
      class="items-center flex"
      v-if="
        replyInformation.attachmentUrls &&
        replyInformation.attachmentUrls.length > 0
      "
    >
      <two-text class="font-bold mr-7 basis-[152px] grow-0 shrink-0" tag="p">
        File attachments
      </two-text>
      <p class="flex">
        <a
          v-for="attachment in replyInformation.attachmentUrls"
          class="flex items-center mr-2"
          target="_blank"
          :href="attachment.url"
        >
          <two-text class="font-bold text-indigo-500 mr-2" tag="span">
            {{ attachment.file_name }}
          </two-text>
          <font-awesome-icon
            icon="fas-solid fa-arrow-up-right-from-square"
            :style="{ color: '#505BF0' }"
          />
        </a>
      </p>
    </div>
  </two-box>
</template>
