/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandApprovalApplicationRequest } from "../models/BrandApprovalApplicationRequest";
import type { BrandApprovalApplicationResponse } from "../models/BrandApprovalApplicationResponse";
import type { BrandApprovalFileUploadRequest } from "../models/BrandApprovalFileUploadRequest";
import type { BrandApprovalFileUploadResponse } from "../models/BrandApprovalFileUploadResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class BrandApprovalApplicationService {
  /**
   * Gets a brand approval application by customer ID.
   * @param customerId Customer ID
   * @returns BrandApprovalApplicationResponse OK
   * @throws ApiError
   */
  public static getBrandApprovalApplication(
    customerId: string
  ): CancelablePromise<BrandApprovalApplicationResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/brand-application/{customerId}",
      path: {
        customerId: customerId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorised`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Store a brand approval application.
   * @param requestBody
   * @returns BrandApprovalApplicationResponse OK
   * @throws ApiError
   */
  public static storeBrandApprovalApplication(
    requestBody: BrandApprovalApplicationRequest
  ): CancelablePromise<BrandApprovalApplicationResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/brand-application",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorised`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Upload files with a brand approval application
   * @param requestBody
   * @returns BrandApprovalFileUploadResponse OK
   * @throws ApiError
   */
  public static uploadFilesWithBrandApprovalApplication(
    requestBody: BrandApprovalFileUploadRequest
  ): CancelablePromise<BrandApprovalFileUploadResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/files",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
