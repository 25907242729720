<script setup lang="ts">
import Modal from "./Modal.vue";
import { ref } from "vue";

defineProps<{
  isForMultipleProducts: boolean;
}>();

const declineReason = ref("");

function isDeclineReasonEmpty() {
  const trimmed = declineReason.value.trim();
  return trimmed === "";
}
</script>

<template>
  <Modal
    @actioned="$emit('declineButtonClicked', declineReason)"
    :isActionButtonDisabled="isDeclineReasonEmpty()"
    actionColor="red"
  >
    <template #header>Decline application</template>
    <template #body>
      <p class="mb-4">
        Are you sure you want to decline
        <span v-if="isForMultipleProducts"> these products? </span>
        <span v-else> this product?</span>
        Doing so will prevent this customer from placing orders for the selected
        <span v-if="isForMultipleProducts"> products.</span>
        <span v-else> product.</span>
      </p>

      <h2 class="text-lg font-semibold mb-2">Reason for decline</h2>

      <p class="text-sm text-gray-500 mb-4">
        The reason will be sent to a Runa staff member and only communicated if
        a customer asks.
      </p>

      <textarea
        v-model="declineReason"
        rows="4"
        class="block p-2.5 w-full text-sm text-gray-800 bg-gray-100 rounded-lg focus:ring-blue-500 focus:border-blue-300"
      />
    </template>
    <template #action-button> Decline </template>
  </Modal>
</template>
