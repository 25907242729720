<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import _ from "lodash";
import {
  TwoText,
  TwoBox,
  TwoButton,
  LoadingSpinner,
} from "@wegift/two-components";
import { BrandApprovalFileUploadResponse } from "../api/brand-approval-application-service";
import { AttachmentUrl } from "../api.generated/models/AttachmentUrl";
import { ref, onMounted, computed } from "vue";
import { formatDate } from "../utils/datetime";
import { useGlobalStore } from "../stores/global";
import { useProductStore } from "../stores/product";
import {
  CUSTOMER_ACTION_VIEW,
  BAND_APPROVE_ACTION_EDIT,
  CUSTOMER_ACTION_EDIT,
} from "../constants";
import {
  submitMoreInformation,
  getMoreInformation,
} from "../services/moreInformation";
import {
  approvePendingRequests,
  declinePendingRequests,
} from "../services/makeDecision";
import DeclineModal from "../components/modals/DeclineModal.vue";
import ApproveModal from "../components/modals/ApproveModal.vue";
import ReplyWithMoreInformation from "../components/ReplyWithMoreInformation.vue";
import YourReply from "../components/YourReply.vue";
import {
  InformationRequestedUpdateCase,
  InformationRequestedCase,
} from "../models/provideInformation";
import MoreInformationRequested from "../components/MoreInformationRequested.vue";
import { captureException } from "../tracing/sentry";

const bannerMsg = {
  forCustomer: {
    title: "Waiting for a decision",
    description:
      "We will notify you about the status of your application or ask for any further information via email.",
  },
  brandApprover: {
    title: "This customer is waiting for a decision",
    description:
      "Please use the information provided by the customer in order to Approve or Decline them.",
  },
};

const globalStore = useGlobalStore();
const productStore = useProductStore();

const router = window.globalRouter || useRouter();
const route = window.globalRoute || useRoute();
const files = ref<string[]>([]);
// for persisting s3 pre signed url and form fields so it can be used before submitting file form generating file urls.
const preSignedUrlsResponses: Record<string, BrandApprovalFileUploadResponse> =
  {};
let action = ref(route.params.action);
const caseReference = route.params.caseReference as string;
let replyInformation = ref<{
  informationProvided: undefined | string | null;
  informationProvidedDatetime: undefined | string;
  attachmentUrls?: AttachmentUrl[] | null;
}>({
  informationProvided: undefined,
  informationProvidedDatetime: undefined,
});
let moreInformation = ref<InformationRequestedCase | null>();
let hasError = ref(false);
let isDeclineModalVisible = ref(false);
let isApproveModalVisible = ref(false);
let isLoading = ref(true);

onMounted(async () => {
  await fetchMoreInformation();
});

const fetchMoreInformation = async () => {
  const { customerId } = globalStore;
  if (!customerId) return;
  try {
    const result = await getMoreInformation({
      customerId,
      caseReference,
    });
    moreInformation.value = result;
    if (action.value !== CUSTOMER_ACTION_EDIT) {
      replyInformation.value = {
        informationProvided: result.informationProvided,
        informationProvidedDatetime: formatDate(
          result.informationProvidedDatetime || Date()
        ),
        attachmentUrls: result.attachmentUrls,
      };
    }
  } catch (e) {
    captureException("An error occurred while fetching more information: ", e);
    hasError.value = true;
  } finally {
    isLoading.value = false;
  }
};

const navigateProductApproval = () => {
  router.back();
};

const handleSubmitMoreInformation = async (
  requestBody: InformationRequestedUpdateCase
) => {
  const { customerId } = globalStore;
  if (!customerId) return;
  isLoading.value = true;
  try {
    await submitMoreInformation({
      customerId,
      caseReference,
      requestBody,
    });

    action.value = CUSTOMER_ACTION_VIEW;
    await fetchMoreInformation();
  } catch (e) {
    captureException(
      "An error occurred while submitting more information: ",
      e
    );
    hasError.value = true;
  } finally {
    isLoading.value = false;
  }
};

function handleDeclineModal() {
  isDeclineModalVisible.value = !isDeclineModalVisible.value;
}

function handleApproveModal() {
  isApproveModalVisible.value = !isApproveModalVisible.value;
}

async function declinePending(declineReason: string) {
  handleDeclineModal();
  const { customerId } = globalStore;
  if (!customerId) return;
  isLoading.value = true;
  try {
    await declinePendingRequests(
      moreInformation.value?.productCodes || [],
      customerId,
      declineReason
    );
    router.back();
  } catch (e) {
    captureException("An error occurred whilst declining decisions: ", e);
    hasError.value = true;
  } finally {
    isLoading.value = false;
  }
}

async function approvePending() {
  handleApproveModal();
  const { customerId } = globalStore;
  if (!customerId) return;
  isLoading.value = true;
  try {
    await approvePendingRequests(
      moreInformation.value?.productCodes || [],
      customerId
    );
    router.back();
  } catch (e) {
    captureException("An error occurred whilst approving decisions: ", e);
    hasError.value = true;
  } finally {
    isLoading.value = false;
  }
}

const multipleProducts = computed(
  () =>
    moreInformation.value &&
    moreInformation.value.productCodes &&
    moreInformation.value.productCodes.length > 1
);
</script>

<template>
  <div>
    <div
      @click="navigateProductApproval()"
      class="mb-6 flex items-center font-bold cursor-pointer"
    >
      <font-awesome-icon
        class="mr-2"
        icon="fas-solid fa-arrow-left"
        :style="{ color: '#667eea' }"
      />
      <two-text class="text-indigo-500">Previous page</two-text>
    </div>
    <div v-if="isLoading" class="m-8 justify-center flex">
      <LoadingSpinner />
    </div>
    <div v-else-if="moreInformation">
      <MoreInformationRequested
        :case-reference="moreInformation.caseReference"
        :product-codes="moreInformation.productCodes"
        :information-requested="moreInformation.informationRequested"
        :request-attachment-urls="moreInformation.requestAttachmentUrls"
        :productCodesAndNames="productStore.productCodesAndNames"
      />
      <ReplyWithMoreInformation
        v-if="action === CUSTOMER_ACTION_EDIT"
        @handle-submit-more-information="handleSubmitMoreInformation"
        :files="files"
        :preSignedUrlsResponses="preSignedUrlsResponses"
      />
      <div v-if="action !== CUSTOMER_ACTION_EDIT">
        <YourReply
          :replyInformation="replyInformation"
          :title="
            action === CUSTOMER_ACTION_VIEW ? 'Your Reply' : 'Customer Reply'
          "
        />
        <two-box class="bg-yellow-50">
          <two-text type="text-base font-bold mb-2" tag="p">
            {{
              action === CUSTOMER_ACTION_VIEW
                ? bannerMsg.forCustomer.title
                : bannerMsg.brandApprover.title
            }}
          </two-text>
          <two-text type="text-base" tag="p">
            {{
              action === CUSTOMER_ACTION_VIEW
                ? bannerMsg.forCustomer.description
                : bannerMsg.brandApprover.description
            }}
          </two-text>
        </two-box>

        <div class="mt-6" v-if="action === BAND_APPROVE_ACTION_EDIT">
          <two-button @click="handleDeclineModal">
            Decline <span v-if="multipleProducts">all</span>
          </two-button>
          <two-button class="ml-2 btn-success" @click="handleApproveModal">
            Approve <span v-if="multipleProducts">all</span>
          </two-button>
        </div>
      </div>
    </div>
    <div v-if="hasError">
      <div
        class="bg-red-100 border border-red-400 text-red-700 text-center p-6 rounded-xl"
        role="alert"
      >
        Unfortunately, we could not load the details of your case history.
        Please try again later.
      </div>
    </div>
    <DeclineModal
      v-if="isDeclineModalVisible"
      @declineButtonClicked="declinePending"
      @closed="handleDeclineModal"
      :isForMultipleProducts="(moreInformation?.productCodes || []).length > 1"
    />
    <ApproveModal
      v-if="isApproveModalVisible"
      @approveButtonClicked="approvePending"
      @closed="handleApproveModal"
      :isForMultipleProducts="(moreInformation?.productCodes || []).length > 1"
    />
  </div>
</template>
