<script setup lang="ts">
const { isActionButtonDisabled, actionColor } = defineProps<{
  isActionButtonDisabled: boolean;
  actionColor?: "red" | "green" | "black";
}>();

let actionButtonStyles: string;

switch (actionColor) {
  case "red":
    actionButtonStyles = "border-red-500 bg-red-500 hover:enabled:bg-red-400";
    break;
  case "green":
    actionButtonStyles =
      "border-green-500 bg-green-500 hover:enabled:bg-green-400";
    break;
  case "black":
  default:
    actionButtonStyles = "border-black bg-black hover:enabled:bg-black";
}
</script>

<template>
  <transition name="modal">
    <div
      class="fixed inset-0 flex items-start justify-center bg-gray-50 bg-opacity-95 z-50"
    >
      <div class="max-w-2xl bg-white rounded-xl shadow-lg">
        <div class="flex flex-col">
          <div class="flex-1 p-4">
            <h1 class="text-3xl font-semibold text-grey-800">
              <slot name="header"> default header </slot>
            </h1>
          </div>

          <div class="flex-1 px-4 text-sm">
            <slot name="body"> default body </slot>
          </div>

          <div class="flex-1 flex justify-center p-4">
            <button
              class="px-6 py-2 text-grey-800 border border-grey-600 rounded-md transition duration-100 ease-in-out hover:bg-gray-100 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              @click="$emit('closed')"
            >
              Close
            </button>
            <button
              :class="[
                'px-6 py-2 ml-2 text-white border rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50',
                actionButtonStyles,
              ]"
              @click="$emit('actioned')"
              :disabled="isActionButtonDisabled"
            >
              <slot name="action-button">Submit</slot>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped></style>
