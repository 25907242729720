<script setup lang="ts">
import {
  TwoText,
  TwoBox,
  TwoLineItem,
  TwoDivider,
} from "@wegift/two-components";
import { ProductCodeToNameMap } from "../utils/types";
import { getProductName } from "../stores/product";
import { AttachmentUrl } from "../api.generated/models/AttachmentUrl";

const { productCodes, productCodesAndNames } = defineProps<{
  productCodes: string[] | undefined;
  caseReference: string | undefined;
  informationRequested: string | undefined;
  productCodesAndNames: ProductCodeToNameMap | null | undefined;
  requestAttachmentUrls?: AttachmentUrl[] | null;
}>();

function getProducts() {
  let productNames: Array<string> = [];
  if (productCodes && productCodesAndNames) {
    productCodes.forEach((code) => {
      productNames.push(getProductName(productCodesAndNames, code));
    });
  } else if (productCodes) {
    productNames = productCodes;
  }

  return (productNames || []).join(", ");
}
</script>

<template>
  <two-text class="mb-4 font-heading font-semiblack" type="text-xl" tag="h1">
    More information requested
  </two-text>
  <two-box class="mb-6">
    <two-line-item title="Products affected:" :description="getProducts()" />
    <two-divider class="mt-2 mb-3" />
    <two-line-item title="Case Ref:" :description="caseReference || ''" />
    <two-divider class="mt-2 mb-3" />
    <two-line-item
      :quote="true"
      title="Your questions:"
      :description="`“${informationRequested || ''}“`"
    />
    <two-divider class="mt-2 mb-3" />
    <div
      class="items-center flex"
      v-if="requestAttachmentUrls && requestAttachmentUrls.length > 0"
    >
      <two-text class="font-bold mr-7 basis-[152px] grow-0 shrink-0" tag="p">
        File attachments
      </two-text>
      <p class="flex">
        <a
          v-for="attachment in requestAttachmentUrls"
          class="flex items-center mr-2"
          target="_blank"
          :href="attachment.url"
        >
          <two-text class="font-bold text-indigo-500 mr-2" tag="span">
            {{ attachment.file_name }}
          </two-text>

          <font-awesome-icon
            icon="fas-solid fa-arrow-up-right-from-square"
            :style="{ color: '#505BF0' }"
          />
        </a>
      </p>
    </div>
  </two-box>
</template>
