import { AccessToken } from "../utils/types";
import {
  ApiError,
  DecisionRequestBatch,
  DecisionStatusEnum,
  DefaultService,
  OpenAPI,
} from "../api.generated";

export const APPROVAL_REASON = "Brand approver has manually approved this.";

export async function approvePendingRequests(
  productCodes: string[],
  customerId: string
): Promise<{ message: string }> {
  try {
    let requestBody = {
      decision: DecisionStatusEnum.APPROVED,
      reason: APPROVAL_REASON,
      customer_id: customerId,
      product_codes: productCodes,
    } as DecisionRequestBatch;

    return postManualBatchDecision(requestBody);
  } catch (e) {
    let result = <ApiError>e;
    console.error(getErrorMessage(result), e);
    throw e;
  }
}

export async function declinePendingRequests(
  productCodes: string[],
  customerId: string,
  declineReason: string
): Promise<{ message: string }> {
  try {
    let requestBody = {
      decision: DecisionStatusEnum.DENIED,
      reason: declineReason,
      customer_id: customerId,
      product_codes: productCodes,
    } as DecisionRequestBatch;

    return postManualBatchDecision(requestBody);
  } catch (e) {
    let result = <ApiError>e;
    console.error(getErrorMessage(result), e);
    throw e;
  }
}

export async function requestMoreInfoForPendingProducts(
  productCodes: string[],
  customerId: string,
  infoRequested: string,
  attachments: string[]
): Promise<{ caseReference: string }> {
  try {
    let requestBody = {
      decision: DecisionStatusEnum.INFORMATION_REQUESTED,
      reason: infoRequested,
      customer_id: customerId,
      product_codes: productCodes,
      more_information_request_attachments: attachments,
    } as DecisionRequestBatch;

    return postManualBatchDecision(requestBody);
  } catch (e) {
    let result = <ApiError>e;
    console.error(getErrorMessage(result), e);
    throw e;
  }
}

async function postManualBatchDecision(requestBody: DecisionRequestBatch) {
  try {
    return await DefaultService.postApiV1DecisionBatch(requestBody);
  } catch (e) {
    let result = <ApiError>e;
    console.error(getErrorMessage(result), e);
    throw e;
  }
}

function getErrorMessage(result: ApiError) {
  return `Error when calling decision api: ${result.message}`;
}
